import NextImage, { ImageProps } from 'next/image';
import { ReactNode } from 'react';
import { Box, ChakraProps } from '@chakra-ui/react';
import { defaultBlurUrl, mtImageLoader } from '@/lib/Image';
import { useConfig } from '@/config';

// next image uses inline-block, which takes line height and then default v-align messes up stuff. This causes images to have 6px more height with 1.4 line-height. Following styles negate this.

interface MhyImageProps {
  children?: ReactNode;
  noRounding?: boolean;
  circle?: boolean;
  containerProps?: ChakraProps;
}

type AllowedStyleProps = Pick<
  ChakraProps,
  | 'borderLeftRadius'
  | 'borderRightRadius'
  | 'borderTopLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomRadius'
  | 'borderTopRadius'
  | 'borderBottomRightRadius'
  | 'borderBottomLeftRadius'
>;

const defaultQuality = 80;
const defaultQualitySmallImages = 90;

const asNum = (x: number | string): number =>
  typeof x === 'string' ? Number.parseInt(x, 10) : x;

const calcDefaultQuality = ({
  width,
  height,
}: Pick<ImageProps, 'width' | 'height'>) => {
  if (!width || !height) {
    return defaultQualitySmallImages;
  }

  return Math.max(asNum(width), asNum(height)) > 150
    ? defaultQuality
    : defaultQualitySmallImages;
};

const Image = ({
  className,
  children,
  noRounding,
  src,
  borderLeftRadius,
  borderRightRadius,
  borderTopRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
  containerProps,
  blurDataURL,
  circle,
  ...props
}: MhyImageProps & ImageProps & AllowedStyleProps) => {
  const { PUBLIC_HOST_URL } = useConfig();

  return (
    <Box
      className={[className, 'image'].filter(Boolean).join(' ')}
      lineHeight="1"
      {...containerProps}
      sx={{
        ...(containerProps?.sx ? containerProps.sx : null),
        '> span': { verticalAlign: 'middle' },
        'img:not([aria-hidden="true"])': {
          // eslint-disable-next-line no-nested-ternary
          borderRadius: noRounding ? 0 : circle ? '999px' : 'lg',
          borderLeftRadius,
          borderRightRadius,
          borderTopRadius,
          borderBottomRadius,
          borderBottomRightRadius,
          borderBottomLeftRadius,
          borderTopLeftRadius,
          borderTopRightRadius,
        },
        img: { pointerEvents: 'none' }, // pointerEvents due to <a><img -structure. refactor into Link if need be
      }}
    >
      <NextImage
        quality={calcDefaultQuality(props)}
        objectFit="cover"
        loader={mtImageLoader(PUBLIC_HOST_URL)}
        {...props}
        blurDataURL={blurDataURL || defaultBlurUrl}
        src={src || '/'}
      />
      {children}
    </Box>
  );
};

export default Image;
